import { useEffect, useState } from "react";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
} from "../../LocalStorage/Locals";
import "../../App.css";
import "../../styles/NotificationStyle.css";
import { setSessionInLocalStorage } from "../../LocalStorage/Locals";
import { Button, notification } from "antd";
import { handleError403 } from "../../api";

export default function EndAllSessions() {
  const [api, contextHolder] = notification.useNotification();
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LOGOFF_ALL&login=" +
          login +
          "&KEY=" +
          secretKey +
          "&SIGN=" +
          SIGN
      );
      responseStatus = response.status;
      const data = await response.json();
      if (data.ok === true) {
        api.open({
          message: "Успех",
          description: data.message,
        });
      }
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    }
  };

  return (
    <div className="main-container-acc">
      {contextHolder}
      <Button
        className="blue-button"
        onClick={() => {
          fetchItemData();
        }}
      >
        Завершить все сессии
      </Button>
    </div>
  );
}
