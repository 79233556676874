import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getSession } from "../LocalStorage/Locals";

export default function PrivateRoute() {
  const location = useLocation();

  return (
    // Если пользователь авторизован, то рендерим дочерние элементы текущего маршрута, используя компонент Outlet
    getSession() === "true" ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  );
}
