import "./App.css";
import useRoutes from "./Routes/useRoutes";

function App() {
  {
    const routes = useRoutes();

    return <>{routes}</>;
  }
}

export default App;
