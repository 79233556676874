import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { Dropdown } from "antd";
import { setSessionInLocalStorage } from "../LocalStorage/Locals";
import icon_fg from "../Images/icon_fg.svg";
import MenuLogo from "../Images/Menu.svg";
import Exit from "../Images/Exit.svg";
import Notification_my from "./Notifications/Notification_my";
import "../styles/Header.css";
import "../styles/FontStyles.css";

const items: MenuProps["items"] = [
  {
    label: (
      <a href="/main" className="menu-item">
        Профиль
      </a>
    ),
    key: "profile",
  },
  {
    label: (
      <a href="/archive" className="menu-item">
        Архив карт
      </a>
    ),
    key: "archive",
  },
  {
    label: (
      <a href="/help" className="menu-item">
        Помощь
      </a>
    ),
    key: "help",
  },
  {
    label: (
      <a href="/tariffs" className="menu-item">
        Каталог
      </a>
    ),
    key: "tariffs",
  },
  {
    label: (
      <a href="/account" className="menu-item">
        Настройки
      </a>
    ),
    key: "account",
  },
];

const Header: React.FC = () => {
  const [current, setCurrent] = useState("mail");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <div className="header-style">
      <div className="headercontainer">
        <div className="group">
          <a href="/main">
            <div className="flex-header-logo">
              <img src={icon_fg} className="logo-img" />
              <p className="BigLogoText">
                СВЯЗЬ
                <br /> НА ВАХТЕ
              </p>
            </div>
          </a>
          <div className=" element-to-show-on-large-screen">
            <Menu
              className="menubig"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            ></Menu>
          </div>
        </div>
        <div className="group element-to-show-on-small-screen">
          <Notification_my />
          <Dropdown
            placement="bottomRight"
            menu={{ items }}
            trigger={["click"]}
          >
            <a className="smallmenu" onClick={(e) => e.preventDefault()}>
              <img src={MenuLogo} className="menu-svg" />
            </a>
          </Dropdown>
          <a
            className="logout-link"
            href="/login"
            onClick={() => {
              setSessionInLocalStorage("false");
            }}
          >
            <img src={Exit} className="exit-svg" />
          </a>
        </div>
        <div className="group element-to-show-on-large-screen">
          <Notification_my />
          <a
            className="logout-link"
            href="/login"
            onClick={() => {
              setSessionInLocalStorage("false");
            }}
          >
            <img src={Exit} className="exit-svg" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
