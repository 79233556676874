import { useState } from "react";
import { Button, Input } from "antd";
import "../App.css";
import React, { useEffect, useRef } from "react";
import {
  RedoOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";

type CaptchaStatus = "loading" | "required" | "inessential";

const generateCaptcha = () => {
  const characters = "0123456789";
  let result = "";
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const CaptchaBox = (props: {
  status: CaptchaStatus;
  forceRegenerate: number;
  onUserCaptcha: (valid: boolean) => void;
}) => {
  const [captcha, setCaptcha] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#f4f4f4";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.font = "30px Arial";
        ctx.fillStyle = "#000";

        // Искажение текста и добавление шума
        for (let i = 0; i < captcha.length; i++) {
          const x = 30 + i * 30;
          const y = 35 + Math.random() * 10;
          const rotate = (Math.random() - 0.5) * (Math.PI / 10); // случайный угол поворота

          ctx.setTransform(
            1,
            Math.random() * 0.1,
            Math.random() * 0.1,
            1,
            x,
            y
          );
          ctx.rotate(rotate);
          ctx.fillText(captcha[i], 0, 0);
        }

        // Сброс трансформации
        ctx.setTransform(1, 0, 0, 1, 0, 0);

        // Добавление линий и шума
        for (let i = 0; i < 5; i++) {
          ctx.beginPath();
          ctx.moveTo(
            Math.random() * canvas.width,
            Math.random() * canvas.height
          );
          ctx.lineTo(
            Math.random() * canvas.width,
            Math.random() * canvas.height
          );
          ctx.strokeStyle = `rgba(0, 0, 0, ${Math.random() * 0.5})`;
          ctx.lineWidth = Math.random() * 5;
          ctx.stroke();
        }
      }
    }
  }, [captcha]);

  const handleRefresh = () => {
    setUserInput("");
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
    props.onUserCaptcha(e.target.value === captcha);
  };

  useEffect(() => {
    setUserInput("");
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
  }, [props.status, props.forceRegenerate]);

  return (
    <div
      style={
        props.status === "required"
          ? {
              height: "5.5rem",
              padding: "1rem",
              margin: "1rem 0rem",
              border: "1px gray solid",
              borderRadius: "0.5rem",
              display: "flex",
              flexFlow: "column nowrap",
              gap: "0.5rem",
              backgroundColor: "#f4f4f4",
              alignItems: "center",
            }
          : {
              height: "5.5rem",
              padding: "1rem",
              margin: "1rem 0rem",
              border: "1px gray solid",
              borderRadius: "0.5rem",
              display: "flex",
              flexFlow: "row nowrap",
              gap: "0.5rem",
              backgroundColor: "#f4f4f4",
              alignItems: "center",
            }
      }
    >
      {props.status === "loading" ? (
        <>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          Подождите...
        </>
      ) : props.status === "inessential" ? (
        <>
          <CheckCircleOutlined />
          Сеть определена.
        </>
      ) : (
        <>
          <canvas ref={canvasRef} width="200" height="50"></canvas>
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              gap: "0.5rem",
            }}
          >
            <Input
              className="custom-input"
              type="number"
              value={userInput}
              placeholder="Введите цифры"
              onChange={handleChange}
            />
            <Button onClick={handleRefresh}>
              <RedoOutlined />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CaptchaBox;
