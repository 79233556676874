import React, { useState, useEffect } from "react";
import "../styles/Spinner.css";
import "../App.css";
import "../styles/PromotionStyle.css";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import { Skeleton } from "antd";
import buy_icon from "../Images/buy-icon.svg";
import { getIP } from "../api";
type PromotionData = {
  name: string;
  url: string;
  image: string;
  dateFrom: string;
  dateTo: string;
};
const Promotion: React.FC = () => {
  const [PromotionList, setPromotionList] = useState<PromotionData[]>([]);
  const login = getLoginStorage();
  const [isLoading, setIsLoading] = useState(false);
  const secretKey = getSecretKey();
  const SIGN = getSIGN();

  const fetchItemData = async () => {
    try {
      setIsLoading(true);
      const ip = await getIP();
      console.log(`Promotions::fetchItemData | fetched ip: ${ip}`);

      await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_ACTIONS&login=" +
          login +
          "&KEY=" +
          secretKey +
          "&SIGN=" +
          SIGN +
          `&IP=${ip || ""}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setPromotionList(data.actions);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchItemData();
  }, []);
  return (
    <div className="PromotionMain">
      <a href="/tariffs">
        <div className="link-box-promotion">
          <div className="promotion-flex ">
            <div className="promotion-circle">
              <div className="buy-icon">
                <img src={buy_icon} alt="Buy Icon" />
              </div>
            </div>
          </div>
          <div className="promotion-flex ">
            <p className="promotion-text">
              Выбрать
              <br /> новую
              <br /> карту
            </p>
          </div>
        </div>
      </a>
      {isLoading && (
        <Skeleton.Image
          active={true}
          style={{
            marginLeft: "10px",
            height: "120px",
            width: "120px",
            borderRadius: "20px",
          }}
        />
      )}
      {PromotionList ? (
        <div style={{ display: "flex" }}>
          {PromotionList.map((PromotionItem, index) => (
            <div key={index} className="PromotionBox">
              <a href={PromotionItem.url} target="_blank">
                <img
                  src={`https://plg.kras.ru/Actions/${PromotionItem.image}`}
                  className="PromotionPic"
                />
              </a>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Promotion;
