import CardList from "../Components/CardList";
import "../styles/TextStyles.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../App.css";
import "../styles/PageStyle.css";
import PersonalInfo from "../Components/PersonalInfo";
import My_cards from "../Images/My_cards.svg";
import ChatwootButton from "../Components/ChatwootButton";
import Promotion from "../Components/Promotion";
function MainPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <PersonalInfo />
          <Promotion />
          <h1 className="Headline-Text">
            <img src={My_cards} className="cards-svg" />
            Мои карты
          </h1>
          <CardList />
          {/* <p className="text-buy">Для выбора карты перейдите </p>{" "}
          <a href="/tariffs" className="button-like">
            В каталог
          </a> */}
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default MainPage;
