import React, { useState } from "react";
import { Button, notification } from "antd";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import "../styles/Spinner.css";
import "../App.css";
import "../styles/ButtonStyle.css";
import Power from "../Images/Power.svg";
import { getIP } from "../api";
type CardInfoProps = {
  cardNo?: string;
};
interface ChildComponentProps {
  update: boolean;
  onUpdateChange: (newUpdate: boolean) => void;
}
const CardActivate: React.FC<CardInfoProps & ChildComponentProps> = ({
  cardNo,
  update,
  onUpdateChange,
}) => {
  const handleButtonClick = () => {
    const newUpdate = !update;
    onUpdateChange(newUpdate);
  };
  const [isLoading, setIsLoading] = useState(false);
  const login = getLoginStorage();
  const [api, contextHolder] = notification.useNotification();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const ipPattern = /(\d{1,3}\.){3}\d{1,3}/;
  const fetchWithTimeout = (url: string, timeout: number) => {
    return Promise.race([
      fetch(url),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Время ожидания истекло")), timeout)
      ),
    ]);
  };
  const fetchItemData = async () => {
    setIsLoading(true);
    try {
      const ip = await getIP();
      console.log(ip);
      console.log(`CardActivate::fetchItemData | fetched ip: ${ip}`);

      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LOGON&login=" +
          login +
          "&KEY=" +
          secretKey +
          "&SIGN=" +
          SIGN +
          "&cardNo=" +
          cardNo +
          `&IP=${ip || ""}`
      );
      const data = await response.json();
      if (data.ok === true) {
        handleButtonClick();
        api.open({
          message: "Успех",
          description: data.message,
        });
        setIsLoading(false);
      }
      if (data.ok === false) {
        console.log(data.error);
        api.open({
          message: "Ошибка",
          description: data.error,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      api.open({
        message: "Ошибка",
        description: "Вы не в нужной сети",
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <Button className="activate-button" onClick={fetchItemData}>
        <img src={Power} /> <p>Старт</p>
      </Button>
      {isLoading && <SpinnerComponent />}
    </div>
  );
};

export default CardActivate;
