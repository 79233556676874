import { useEffect, useState } from "react";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import "../App.css";
import "../styles/CardStyle.css";
import CardInfo from "./CardInfo";
import CardPay from "./CardPay";
import CardActivate from "./CardActivate";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import { setSessionInLocalStorage } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import CardDeactivate from "./CardDeactivate";
import Options from "./Options";
import { getIP, handleError403 } from "../api";
import TimeChecker from "./timecheck";
type CardsData = {
  title: string;
  description?: string;
  cardNo: string;
  nominal: number;
  balance: number;
  isReplenishable: boolean;
  type: string;
  bgcolor: string;
  traffic: number;
  trafficTotal: number;
  display: string;
  boughtOn: number;
  activatedOn: number;
  login: string;
  password: string;
  isBlockable: boolean;
  isUnblockable: boolean;
  sessionIp: string;
  time: string;
  present: string;
  presentFrom: string;
  presentDate: number;
  actions: {
    code: string;
    name: string;
    description: string;
    cardtypes: {
      code: string;
      units: number;
      title: string;
      description: string;
    }[];
    cardno: string;
  }[];
};

export default function Cards() {
  const [swiperParams, setSwiperParams] = useState({
    slidesPerView: 2,
    spaceBetween: 20,
  });
  const updateSwiperParams = () => {
    setSwiperParams({
      slidesPerView:
        window.innerWidth <= 710 ? 1 : window.innerWidth >= 1110 ? 3 : 2,
      spaceBetween: 0,
    });
  };

  useEffect(() => {
    updateSwiperParams();
    window.addEventListener("resize", updateSwiperParams);
    return () => {
      window.removeEventListener("resize", updateSwiperParams);
    };
  }, []);
  const [update, setUpdate] = useState<boolean>(false);
  const handleUpdateChange = (newUpdate: boolean) => {
    setUpdate(newUpdate);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [Items, setItems] = useState<CardsData[]>([]);
  const login = getLoginStorage();

  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      setIsLoading(true);
      const ip = await getIP();
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LIST_WITH_ACTIONS&login=" +
          getLoginStorage() +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          `&IP=${ip || ""}`
      );
      responseStatus = response.status;
      const data = await response.json();
      setItems(data.cards);
    } catch (error) {
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
        return;
      } else {
        console.log("Error2:", responseStatus);
        setSessionInLocalStorage("false");
        window.location.reload();
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchItemData();
  }, [update]);

  return (
    <div>
      {isLoading && <SpinnerComponent />}
      <div className="card-container">
        {Items[0] == null && !isLoading && (
          <p className="text-tariftitle">Пока у вас нет карт</p>
        )}
        <Swiper
          {...swiperParams}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {Items.map((item) => (
            <SwiperSlide key={parseInt(item.cardNo, 10)}>
              <div
                style={{
                  filter: item.isUnblockable ? "grayscale(1)" : "none",
                }}
              >
                <div
                  className="custom-card"
                  style={{
                    backgroundImage: item.bgcolor
                      ? `linear-gradient(to right, #304782, ${item.bgcolor})`
                      : `linear-gradient(to right, #304782, #304782)`,
                  }}
                >
                  <div className="flex-row">
                    <div className="flex-row">
                      <p className="text">Номинал:</p>
                      <p className="text-nominal">{item.nominal}</p>
                    </div>
                    <div className="flex-row">
                      <p className="text">Баланс:</p>
                      <p className="text-balance">{Math.floor(item.balance)}</p>
                    </div>
                  </div>
                  <div
                    className="flex-row "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="flex-col">
                      <p className="text-tariftype">{item.type}</p>

                      <p className="text-tariftitle ">{item.title}</p>
                      {item.traffic ? (
                        <p className="trafic">
                          Остаток трафика {Math.floor(item.traffic)} из{" "}
                          {Math.floor(item.trafficTotal)} Мбайт
                        </p>
                      ) : (
                        <div className="blank-space-traffic" />
                      )}
                    </div>
                    {item.time && <TimeChecker time={item.time} />}
                  </div>

                  <div className="flex-row-buttons-mainpage">
                    <div className="flex-row-buttons-left">
                      <div>
                        <CardInfo
                          title={item.title}
                          cardNo={item.cardNo}
                          description={item.description}
                          display={item.display}
                          cardLogin={item.login}
                          cardPassword={item.password}
                          isBlockable={item.isBlockable}
                          isUnblockable={item.isUnblockable}
                          update={update}
                          onUpdateChange={handleUpdateChange}
                          boughtOn={item.boughtOn}
                          activatedOn={item.activatedOn}
                          present={item.present}
                          presentDate={item.presentDate}
                          presentFrom={item.presentFrom}
                        />
                      </div>
                      <div className="button-payement">
                        {item.isReplenishable === true && (
                          <CardPay
                            cardNo={item.cardNo}
                            nominal={item.nominal}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex-row-buttons-right">
                      <div>
                        {item.sessionIp === null ? (
                          <CardActivate
                            cardNo={item.cardNo}
                            update={update}
                            onUpdateChange={handleUpdateChange}
                          />
                        ) : (
                          <CardDeactivate
                            cardNo={item.cardNo}
                            update={update}
                            onUpdateChange={handleUpdateChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Options actions={item.actions} />
              <div className="blank-space-traffic" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
