import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CollapseHelp from "../Components/CollapseHelp";
import HelpChats from "../Components/HelpChats";
import ChatwootButton from "../Components/ChatwootButton";
import HelpSvg from "../Images/HelpPageIcon.svg";
import CollapseHelpInstructions from "../Components/CollapseHelpInstructions";
function HelpPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <h1 className="Headline-Text">
            <img src={HelpSvg} className="help-svg" /> Помощь
          </h1>
          <HelpChats />
          <h1 className="Headline-Text"> Часто задаваемые вопросы</h1>
          <CollapseHelp />
          <CollapseHelpInstructions />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default HelpPage;
