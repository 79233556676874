import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "../Pages/MainPage";
import PrivateRoute from "./PrivateRoute";
import Loginpage from "../Pages/Loginpage";
import HelpPage from "../Pages/HelpPage";
import ArchivePage from "../Pages/ArchivePage";
import TariffsPage from "../Pages/TariffsPage";
import LoginRedirect from "../Pages/LoginRedirect";
import NotificationPage from "../Pages/NotificationPage";
import AccountPage from "../Pages/AccountPage";
// import LoginpageTest from "../Pages/LoginpageTest";
export const useRoutes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route index element={<Loginpage />} />
          <Route path="/login" element={<Loginpage />} />
          {/* <Route path="/logintest" element={<LoginpageTest />} /> */}
          <Route path="/loginredirect" element={<LoginRedirect />} />
          <Route element={<PrivateRoute />}>
            <Route path="/main" element={<MainPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/tariffs" element={<TariffsPage />} />
            <Route path="/archive" element={<ArchivePage />} />
            <Route path="/notification" element={<NotificationPage />} />
            <Route path="/account" element={<AccountPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default useRoutes;
