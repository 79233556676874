import { useEffect, useState } from "react";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
  getFormattedLogin,
} from "../../LocalStorage/Locals";
import "../../App.css";
import "../../styles/AccountStyle.css";
import { setSessionInLocalStorage } from "../../LocalStorage/Locals";
import { Popover } from "antd";
import UserSvg from "../../Images/Circle-user-round.svg";
import LoginSvg from "../../Images/Exit-for-settings.svg";
import PhoneSvg from "../../Images/Phone-settings.svg";
import { Skeleton } from "antd";
import IpIcon from "../../Images/ip-address.svg";
import { getIP, handleError403 } from "../../api";

export default function AccuntDetails() {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const login = getLoginStorage();
  const FormattedLogin = getFormattedLogin();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const [FIO, setFIO] = useState("");
  const [ipAddress, setIPAddress] = useState<string | null>(null);

  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LIST_WITH_ACTIONS&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN()
      );
      responseStatus = response.status;
      const data = await response.json();
      setFIO(data.fullname);
    } catch (error) {
      console.log("Error:", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    } finally {
    }
  };

  const fetchIP = async () => {
    setIsLoading(true);
    const ip = await getIP();
    console.log(`AccountDetails::useEffect (startup hook) | fetched ip: ${ip}`);

    setIPAddress(ip);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchItemData();
    fetchIP();
  }, []);
  return (
    <div className="main-container-acc">
      <div className="acc-row-container">
        <img alt="" src={UserSvg} className="left-svg" />
        <div className="acc-container">
          <h5 className="headline-acc">Ф.И.О.</h5>
          {FIO ? (
            <p className="text-acc">{FIO}</p>
          ) : (
            <p className="text-acc">Не указано</p>
          )}
        </div>
      </div>
      <div className="acc-row-container">
        <img alt="" src={LoginSvg} className="left-svg" />
        <div className="acc-container">
          <h5 className="headline-acc">Логин</h5>
          <p className="text-acc">{FormattedLogin}</p>
        </div>
      </div>
      <div className="acc-row-container">
        <img alt="" src={IpIcon} className="left-svg" />
        <div className="acc-container">
          <h5 className="headline-acc">IP-адрес</h5>
          <p className="text-acc">
            {isLoading ? (
              <Skeleton.Input style={{ maxHeight: "21px" }} active />
            ) : (
              <>{ipAddress || "Вы находитесь не в нашей сети"}</>
            )}
          </p>
        </div>
      </div>
      <div className="acc-row-container acc-row-container-additionalborder">
        <img alt="" src={PhoneSvg} className="left-svg" />
        <div className="acc-container">
          <Popover
            content={
              <a
                href="https://xn--80aaebqi6b3ap3a6e8a.xn--p1acf/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Связь на вахте
              </a>
            }
            title=""
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <div>
              <h5
                style={{
                  width: "fit-content",
                  cursor: "pointer",
                }}
                className="headline-acc"
              >
                О компании
              </h5>
              <p className="text-acc">Нажмите, чтобы перейти на сайт</p>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}
