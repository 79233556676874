import React, { useEffect, useState } from "react";
import pic1 from "../Images/Chatwoot.svg";
import pic2 from "../Images/ChatwootChat.svg";
import pic3 from "../Images/ChatwootTg.svg";
import pic4 from "../Images/ChatwootClose.svg";
import "../styles/Chatwoot.css";
const ChatwootButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false); // little hack to make it work

    const script = document.createElement("script");

    script.id = "chatwoot-sdk";
    script.src = "/Chatwoot_script.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [isAdditionalHelpOpen, setIsAdditionalHelpOpen] = useState(false);
  const updateCustomAttributes = (contractNo: string): void => {
    setTimeout(() => {
      if ((window as any).$chatwoot) {
        (window as any).$chatwoot.setCustomAttributes({
          contractNo,
          currentPage: window.location.toString(),
        });
      }
    }, 10000);
  };

  const toggleHelp = (): void => {
    setIsAdditionalHelpOpen((prevState) => !prevState);
  };

  const handleOpenTg = (): void => {
    window.open("https://t.me/svyaznavahte_bot", "_blank");
  };

  const handleOpenChat = (): void => {
    updateCustomAttributes("1234567890");
    if ((window as any).$chatwoot) {
      (window as any).$chatwoot.toggle("open");
    }
  };

  return (
    <div>
      <div
        className={`general-help-button main-help-button ${
          isAdditionalHelpOpen ? "closed" : ""
        }`}
        onClick={toggleHelp}
      >
        <img src={pic1} alt="Help" />
      </div>

      <div
        className={`additional-help-buttons ${
          isAdditionalHelpOpen ? "" : "closed"
        }`}
      >
        <img
          className="general-help-button additional-help-button telegram"
          src={pic2}
          alt="Telegram"
          onClick={handleOpenTg}
        />
        <img
          className="general-help-button additional-help-button chatwoot"
          src={pic3}
          alt="Chatwoot"
          onClick={handleOpenChat}
        />
        <img
          className="general-help-button additional-help-button cancel"
          src={pic4}
          alt="Cancel"
          onClick={toggleHelp}
        />
      </div>
    </div>
  );
};

export default ChatwootButton;
