import { useEffect, useState } from "react";
import "../App.css";
import "../styles/Options.css";
import { RightOutlined } from "@ant-design/icons";
import OptionsModal from "./OptionsModal";
import { Button, Modal, Radio } from "antd";
import placeholder from "../Images/Options_Placeholder.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
type CardsData = {
  actions: {
    code: string;
    name: string;
    description: string;
    cardtypes: {
      code: string;
      units: number;
      title: string;
      description: string;
    }[];
    cardno: string;
  }[];
};

const Options: React.FC<CardsData> = ({ actions }) => {
  // console.log(actions);
  const [isModalOpen, setIsModalInfoOpen] = useState(false);
  // const [hideOptions, setHideOptions] = useState(true);
  return (
    <>
      {" "}
      <div className="option-containter">
        <div className={`content-container-options `}>
          {actions.length !== 0 ? (
            <div className="option-info">
              <p>
                Опции{" "}
                <InfoCircleOutlined onClick={() => setIsModalInfoOpen(true)} />
              </p>
            </div>
          ) : (
            <></>
          )}

          <Modal
            title="Что такое опция?"
            open={isModalOpen}
            footer={[
              <Button key="cancel" onClick={() => setIsModalInfoOpen(false)}>
                Ок
              </Button>,
            ]}
            onCancel={() => setIsModalInfoOpen(false)}
          >
            <p>
              Опция - это дополнение к основному тарифу. Купить опцию без тарифа
              нельзя. Набор опций, доступных к покупке, зависит от подключенного
              тарифа, географического положения, вахтового объекта, диапазона
              связи(Ka- или Ku), загруженности сети и её технических
              возможностей. Следите за обновлениями, чтобы не пропускать
              выгодные предложения от "Связи на вахте".
            </p>
          </Modal>
          {actions.length === 0 ? (
            <></>
          ) : (
            // <div>
            //   <div className="opiton-box" onClick={() => setIsModalOpen(true)}>
            //     {/* <p style={{ textAlign: "center" }}>Что такое опция?</p> */}
            //     <img src={placeholder} />
            //   </div>
            //   <p className="option-text-under">Что такое опция?</p>
            //   <Modal
            //     title="Что такое опция?"
            //     open={isModalOpen}
            //     footer={[
            //       <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            //         Ок
            //       </Button>,
            //     ]}
            //     onCancel={() => setIsModalOpen(false)}
            //   >
            //     <p>
            //       Опция - это дополнение к основному тарифу. Купить опцию без
            //       тарифа нельзя. Набор опция, доступных к покупке, зависит от
            //       подключенного тарифа, геограифеского положения, вахтового
            //       объекта, диапозона связи(Ka- или Ku), загруженности сети и её
            //       технических возможностей. Следите за обновлениями, чтобы не
            //       пропускать выгодные предложения от "Связи на вахте".
            //     </p>
            //   </Modal>
            // </div>
            <></>
          )}
          {actions.map((item) => (
            <div key={item.code}>
              <div>
                <OptionsModal
                  code={item.code}
                  name={item.name}
                  description={item.description}
                  cardtypes={item.cardtypes}
                  cardno={item.cardno}
                />
              </div>
            </div>
          ))}
        </div>

        <div></div>
        {/* <RightOutlined
            className={hideOptions ? "rotate-90-options" : "rotate90-options"}
            style={{
              transform: hideOptions ? "rotate(90deg)" : "rotate(-90deg)",
              color: "white",
            }}
          /> */}
      </div>
    </>
  );
};

export default Options;
