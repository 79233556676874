import Tariffs from "../Components/Tariffs";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../App.css";
import "../styles/TextStyles.css";
import ChatwootButton from "../Components/ChatwootButton";
import tarifSvg from "../Images/Tarrifs.svg";
import RecSlider from "../Components/Recommendation/RecSlider";
function TariffsPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <RecSlider />
          <p className="Headline-Text">
            <img src={tarifSvg} className="user-acc-svg" /> Карты
          </p>
          <Tariffs />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default TariffsPage;
