import React, { useEffect, useState } from "react";
const TimeChecker = ({ time }: { time: string }) => {
  const timeString = time;
  const [currentTime, setCurrentTime] = useState(new Date());
  const [startHourString, endHourString] = timeString.split("/");
  const startHour = parseInt(startHourString, 10);
  const endHour = parseInt(endHourString, 10);

  const updateCurrentTime = () => {
    setCurrentTime(new Date());
  };

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}ч ${mins}мин`;
  };

  const utcTime = new Date(
    currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
  );
  const adjustedTime = new Date(utcTime.getTime() + 7 * 60 * 60 * 1000);

  const currentMinutes =
    adjustedTime.getHours() * 60 + adjustedTime.getMinutes();

  const startMinutes = startHour * 60;
  const endMinutes = endHour * 60;

  let timeUntil: string;
  let isWithinTimeRange: boolean = false;

  if (currentMinutes < startMinutes) {
    const diff = startMinutes - currentMinutes;
    timeUntil = `через: ${formatTime(diff)}`;
    isWithinTimeRange = false;
  } else if (currentMinutes >= startMinutes && currentMinutes < endMinutes) {
    const diff = endMinutes - currentMinutes;
    timeUntil = `через: ${formatTime(diff)}`;
    isWithinTimeRange = true;
  } else {
    const diff = 24 * 60 - currentMinutes + startMinutes;
    timeUntil = `через: ${formatTime(diff)}`;
    isWithinTimeRange = false;
  }
  useEffect(() => {
    // Запускаем таймер, который обновляет время каждую минуту
    const timer = setInterval(() => {
      updateCurrentTime();
    }, 60000); // 60000 миллисекунд = 1 минута

    // Очищаем таймер при размонтировании компонента
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isWithinTimeRange ? (
          <img
            src="/Images/Inside.svg"
            alt="Внутри промежутка"
            style={{ width: "40px", height: "40px" }}
          />
        ) : (
          <img
            src="/Images/Outside.svg"
            alt="Снаружи промежутка"
            style={{ width: "40px", height: "40px" }}
          />
        )}
      </div>
      <span
        style={{
          display: "flex",
          color: "white",
          fontSize: "12px",
          marginRight: "8px",
          marginTop: "2px",
        }}
      >
        {timeUntil}
      </span>
    </div>
  );
};

export default TimeChecker;
