import React, { useState } from "react";
import { Button, Modal } from "antd";
import "../styles/ButtonStyle.css";
import { InfoCircleOutlined } from "@ant-design/icons";
type CardInfoProps = {
  code: string;
  descr: string;
  type?: string;
  name?: string;
  daysTotal?: number;
  traffic?: number;
  speed?: number;
};

const CardBuyInfo: React.FC<CardInfoProps> = ({
  descr,
  type,
  daysTotal,
  traffic,
  speed,
  name,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button className="blue-button" onClick={() => setIsModalOpen(true)}>
        <InfoCircleOutlined />
        Описание
      </Button>
      <Modal
        className="regular_text_style"
        title="Информация о карте"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="ok"
            className="blue-button"
            onClick={() => setIsModalOpen(false)}
          >
            ОК
          </Button>,
        ]}
      >
        <p>Наименование: {name}</p>
        <p>Тип карты: {type}</p>
        <p>Описание: {descr}</p>
        {daysTotal && <p> Срок действия тарифа: {daysTotal}</p>}
        {traffic && <p>Включенный трафик в МБ: {traffic}</p>}
        {speed && <p>Скорость кбит/сек: {speed}</p>}
      </Modal>
    </>
  );
};

export default CardBuyInfo;
