import Login from "../Components/Login";
import "../App.css";
import ChatwootButton from "../Components/ChatwootButton";
// import { useEffect } from "react";

function Loginpage() {
  // useEffect(() => {
  //   window.location.href = "https://plg.kras.ru/Views/Login.aspx";
  // });
  return (
    <>
      <div>
        <Login />
        <div className="regbutton"></div>
        <ChatwootButton />
      </div>
    </>
  );
}

export default Loginpage;
