import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "../styles/CardStyle.css";

const CollapseHelp: React.FC = () => {
  const colitems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Забыли логин?",
      children: (
        <div>
          <p>
            Логином для входа в личный кабинет является номер сотового телефона.
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: "Восстановить пароль?",
      children: (
        <div>
          <p>
            Если в вашем поселке есть сотовая связь, перейдите по ссылке
            plg.kras.ru, введите номер телефона и нажмите «Получить пароль по
            СМС». На Ваш телефон поступит СМС-сообщение со старым паролем. Если
            вы хотите получить новый, то нажмите «Получить новый пароль по СМС».
            <br />
            Если в вашем посёлке нет сотовой связи, обратитесь в наш
            контакт-центр через кнопку чата в правом нижнем углу экрана.
            <br />
            Обращаем внимание, что наши специалисты консультируют с 8:00 до
            21:00 по красноярскому времени. В круглосуточном режиме работает
            горячая линия <a href="tel:+78043330284">8 804 333 02 84</a>.
          </p>
        </div>
      ),
    },
    {
      key: "3",
      label: "Какой тариф выбрать?",
      children: (
        <div>
          <p>
            Наши тарифы делятся на лимитированные, посуточные и безлимитные.
            <br />
            <b>Безлимитные тарифы</b> включают в себя объем трафика на высокой
            скорости, после исчерпания которого скорость доступа в Интернет
            снижается, при этом пользоваться услугами можно до конца действия
            тарифа.
            <br />
            <b>Посуточные тарифы</b> включают объем трафика на высокой скорости
            с посуточным делением. После исчерпания суточного трафика скорость
            снижается. Данный тип тарифа позволяет распределить трафик
            равномерно в течение месяца.
            <br />
            <b>Лимитированные тарифы</b> включают ограниченное количество
            трафика. Доступ в сеть Интернет прекращается после исчерпания
            трафика.
            <br />
          </p>
        </div>
      ),
    },
    {
      key: "4",
      label: "Способы оплаты?",
      children: (
        <div>
          <p>
            Оплатить услуги связи можно двумя способами: банковской картой или
            через СБП.
          </p>
        </div>
      ),
    },
  ];

  const onChange = (key: string | string[]) => {};

  return (
    <div>
      <Collapse size="large" items={colitems} onChange={onChange} />
    </div>
  );
};
export default CollapseHelp;
