import { useEffect, useState } from "react";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import "../App.css";
import "../styles/CardStyle.css";
import "../styles/CardHistory.css";
import CardInfoArchive from "./CardInfoArchive";
import { setSessionInLocalStorage } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import { handleError403 } from "../api";
type CardsData = {
  title: string;
  description?: string;
  cardNo: string;
  nominal: number;
  balance?: number;
  boughtOn: number;
  type: string;
  bgcolor: string;
  display: string;
  login: string;
  password: string;
  isBlockable: boolean;
  isUnblockable: boolean;
  isReplenishable: boolean;
};

export default function CardsArchive() {
  const [isLoading, setIsLoading] = useState(false);
  const [Items, setItems] = useState<CardsData[]>([]);
  const login = getLoginStorage();
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_ARCHIVE&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN()
      );
      responseStatus = response.status;
      const data = await response.json();
      setItems(data.cards);
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, []);
  return (
    <div className="card-history">
      {isLoading && <SpinnerComponent />}
      <table>
        <thead>
          <tr>
            <th>Карта</th>
            <th></th>
            <th>Приобретена</th>
          </tr>
        </thead>
        <tbody>
          {Items.map((item) => (
            <tr key={parseInt(item.cardNo, 10)}>
              <td>
                {item.cardNo}
                <br />
                {item.title}
                <br />
                Баланс: {item.balance}
              </td>
              <td>
                <CardInfoArchive
                  title={item.title}
                  cardNo={item.cardNo}
                  display={item.display}
                  cardLogin={item.login}
                  description={item.description}
                  cardPassword={item.password}
                  isBlockable={item.isBlockable}
                  isUnblockable={item.isUnblockable}
                  isReplenishable={item.isReplenishable}
                  nominal={item.nominal}
                />
              </td>
              <td>{new Date(item.boughtOn * 1000).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
