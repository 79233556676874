import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import {
  setSessionInLocalStorage,
  setLoginStorage,
  setSecretKey,
  setSIGN,
} from "../LocalStorage/Locals";

import "../App.css";

function LoginRedirect() {
  const params = new URLSearchParams(window.location.search);
  let login = params.get("login");
  const SIGN = params.get("SIGN");
  const secretKey = params.get("secretKey");

  const navigate = useNavigate();
  const onSubmitMetod = async () => {
    setSessionInLocalStorage("true");

    if (
      typeof login === "string" &&
      typeof secretKey === "string" &&
      typeof SIGN === "string"
    ) {
      setLoginStorage(login);
      setSecretKey(secretKey);
      setSIGN(SIGN);
    } else {
      navigate("/login");
    }
    try {
      const fetchItemData = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LIST_WITH_ACTIONS&login=" +
          login +
          "&KEY=" +
          secretKey +
          "&SIGN=" +
          SIGN
      );
      const data = await fetchItemData.json();
      if (data.ok === false) {
        navigate("/login");
      } else {
        navigate("/main");
      }
    } catch (error) {
      navigate("/login");
    }
  };
  useEffect(() => {
    onSubmitMetod();
  }, []);

  return <></>;
}

export default LoginRedirect;
