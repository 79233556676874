import { fetchWithTimeout } from "./utils";
import {
  setSessionInLocalStorage,
 
  setSecretKey,
  setSIGN,
  getPass
} from "./LocalStorage/Locals";
import { Md5 } from "ts-md5";
const ipPattern = /(\d{1,3}\.){3}\d{1,3}/;

export const getIP = async () => {
    try {
        const request = (await fetchWithTimeout("https://z.kras.ru/ClientIp.ashx", 5000)) as Response;
        const data = await request.text();
        console.log(`text from response: ${data}`);

        const extractedIp = data.match(ipPattern);
        console.log(`matched text: ${extractedIp}`);

        if (extractedIp !== null) {
            const collectedIP = extractedIp[0].toString();
            console.log(`IP: ${collectedIP}`);
            return collectedIP;
        }
    } catch (error) {
        console.error(`IP fetch error: ${error}`);
    }

    return null;
};
export const getIPfaster = async () => {
    try {
        const request = (await fetchWithTimeout("https://z.kras.ru/ClientIp.ashx", 3000)) as Response;
        const data = await request.text();
        console.log(`text from response: ${data}`);

        const extractedIp = data.match(ipPattern);
        console.log(`matched text: ${extractedIp}`);

        if (extractedIp !== null) {
            const collectedIP = extractedIp[0].toString();
            console.log(`IP: ${collectedIP}`);
            return collectedIP;
        }
    } catch (error) {
        console.error(`IP fetch error: ${error}`);
    }

    return null;
};

export const handleError403 = async (login: string) => {
    const fetchKeyResponse = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=GETKEY&login=" + login
    );
    const key = await fetchKeyResponse.json();
    const password = getPass();
    setSessionInLocalStorage("true");
    setSecretKey(key);
    const md5HashKey = Md5.hashStr("G2vXrSAGm9").toString();
    const stringformd5 = `${login}::${password}::${key}::1::::${md5HashKey}`;
    const Sign = Md5.hashStr(stringformd5).toString();
    setSIGN(Sign);
    
  };