import { Button, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { getLoginStorage, getSIGN, getSecretKey } from "../LocalStorage/Locals";
type Props = {
  cardNo: string;
  title: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const CardGift = ({ cardNo, title, setOpen }: Props) => {
  const [isGiftModalOpen, setGiftModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmIsModalOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const fetchresponse = async () => {
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_PRESENT&login=" +
        getLoginStorage() +
        "&KEY=" +
        getSecretKey() +
        "&SIGN=" +
        getSIGN() +
        "&cardNo=" +
        cardNo +
        "&user=" +
        phone
    );
    const data = await response.json();
    if (data.ok === true) {
      api.open({
        message: "Успех",
        description: "Карта успешно подарена",
      });
    } else if (data.error) {
      console.log(data.error);
      api.open({
        message: "Ошибка",
        description: data.error,
      });
    }
  };

  const handleOk = () => {
    setGiftModalOpen(false);
    setConfirmIsModalOpen(true);
  };

  const handleOkSend = () => {
    fetchresponse();
    setConfirmIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Button
        style={{ marginBottom: "10px" }}
        onClick={() => (setGiftModalOpen(true), setOpen(false))}
      >
        Подарить
      </Button>
      <Modal
        title="Подарить карту"
        open={isGiftModalOpen}
        onCancel={() => setGiftModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setGiftModalOpen(false)}>
            Отмена
          </Button>,
          <Button key="ok" className="blue-button" onClick={handleOk}>
            ОК
          </Button>,
        ]}
      >
        <span>
          Введите кому вы хотите подарить карту - {title},<br /> Номер карты -{" "}
          {cardNo}
        </span>
        <Input
          className="custom-input"
          type="number"
          value={phone}
          placeholder="Номер телефона без +7"
          onChange={(e) => setPhone(e.target.value)}
        />
      </Modal>
      <Modal
        title="Подтверждение"
        open={isConfirmModalOpen}
        onCancel={() => setConfirmIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmIsModalOpen(false)}>
            Отмена
          </Button>,
          <Button key="ok" className="blue-button" onClick={handleOkSend}>
            ОК
          </Button>,
        ]}
      >
        Вы уверены что хотите подарить карту {title} пользователю с номером{" "}
        {phone}
      </Modal>
    </>
  );
};

export default CardGift;
