import "../styles/Footer.css";
import "../styles/FontStyles.css";
import RuStore_logo from "../Images/RuStore_logo.svg";
import Playstore from "../Images/Playstore.svg";
const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-left">
        <p>Подключение и поддержка клиентов:</p>
        <h1>8 804 333 02 84</h1>
      </div>
      <div className="footer-right">
        <p>
          Скачивайте <br /> наше приложение!
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=ru.kras.plg.mobile"
          target="_blank"
        >
          <img className="icon" src={Playstore} />{" "}
        </a>
        <a
          href="https://apps.rustore.ru/app/ru.kras.plg.mobile.rustore"
          target="_blank"
        >
          <img className="icon" src={RuStore_logo} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
