import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import NotificationAll from "../Components/Notifications/NotificationAll";
import Notification from "../Images/Notification.svg";
import "../styles/PageStyle.css";
import ChatwootButton from "../Components/ChatwootButton";
function NotificationPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <h1 className="Headline-Text">
            {" "}
            <img src={Notification} className="notif-svg" /> Уведомления
          </h1>
          <NotificationAll />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default NotificationPage;
