import { useEffect, useState } from "react";
import "../../App.css";
import "../../styles/NotificationStyle.css";
import { RightOutlined } from "@ant-design/icons";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
  setSessionInLocalStorage,
} from "../../LocalStorage/Locals";
import { handleError403 } from "../../api";
type Notif_type = {
  id: number;
  noteDate: string;
  readDate: string;
  title: string;
  text: string;
};
const NotificationCol: React.FC<Notif_type> = ({
  id,
  title,
  text,
  noteDate,
  readDate,
}) => {
  const login = getLoginStorage();
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=NOTE_READ&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          "&note_id=" +
          id
      );
      responseStatus = response.status;
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    } finally {
    }
  };

  const [hideNotif, setHideNotif] = useState(true);
  const [unreadNotif, setUnreadNotif] = useState(false);
  useEffect(() => {
    if (readDate === null && !unreadNotif) {
      setUnreadNotif(true);
    }
  }, [readDate]); //для прочтения уведомлений
  return (
    <div>
      <div
        key={id}
        className={!unreadNotif ? "allnotif-box" : "allnotifUnread-box"} //стиль для непрочитанного уведомления и прочитанного
      >
        <div
          className="notif-header-row"
          onClick={() => {
            setHideNotif(!hideNotif);
            if (unreadNotif) {
              //для прочтения уведомлений
              setUnreadNotif(false);
              fetchItemData();
            }
          }}
        >
          <RightOutlined
            className={`pointer-container ${
              hideNotif ? "right-pointer" : "bottom-pointer"
            }`}
          />
          <div className="title-and-date">
            <div
              dangerouslySetInnerHTML={{ __html: title }}
              className="allnotif-title"
            />
            <p className="allnotif-date">Дата: {noteDate} </p>
          </div>
        </div>
        <div
          className={`content-container ${hideNotif ? "hidden" : "visible"}`}
        >
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="allnotif-text"
          ></div>
        </div>
      </div>
    </div>
  );
};
export default NotificationCol;
