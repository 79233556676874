import { useEffect, useState } from "react";
import { Badge, Popover } from "antd";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
} from "../../LocalStorage/Locals";
import "../../App.css";
import "../../styles/NotificationStyle.css";
import Notification from "../../Images/Notification.svg";
import { setSessionInLocalStorage } from "../../LocalStorage/Locals";
import { useNavigate } from "react-router-dom";
import NotificationRead from "./NotificationRead";
import { handleError403 } from "../../api";
type Notif_type = {
  id: number;
  noteDate: string;
  readDate: string;
  title: string;
  text: string;
};

export default function Notification_my() {
  const navigate = useNavigate();
  const [update, setUpdate] = useState<boolean>(false);
  const handleUpdateChange = (newUpdate: boolean) => {
    setUpdate(newUpdate);
  };
  const [open, setOpen] = useState(false);
  const login = getLoginStorage();
  const [Notif_list, setNotif_list] = useState<Notif_type[]>([]);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const fetchItemDataAll = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=NOTE_LIST&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          "&archive=1&count=35"
      );
      responseStatus = response.status;
      const data = await response.json();
      navigate("/notification?count=" + data.notes.length);
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemDataAll();
      } else {
        setSessionInLocalStorage("false");
      }
    } finally {
    }
  };
  const handleAllNotificationsClick = async () => {
    await fetchItemDataAll();
  };
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=NOTE_LIST&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          "&archive=0"
      );
      responseStatus = response.status;
      const data = await response.json();
      setNotif_list(data.notes);
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    } finally {
    }
  };
  useEffect(() => {
    fetchItemData();
  }, [update]);
  return (
    <div className="center">
      <Popover
        content={
          <div className="notif-box">
            {!Notif_list.length && <p>Пока нет новых уведомлений</p>}
            {Notif_list.map((Item) => (
              <div key={Item.id}>
                <div
                  dangerouslySetInnerHTML={{ __html: Item.title }}
                  className="header-text"
                />

                <div
                  className="notif-text"
                  dangerouslySetInnerHTML={{ __html: Item.text }}
                ></div>
                <NotificationRead
                  id={Item.id}
                  update={update}
                  onUpdateChange={handleUpdateChange}
                />
              </div>
            ))}
            <div className="sticky-link">
              <a onClick={handleAllNotificationsClick}>Все уведомления</a>
            </div>
          </div>
        }
        title="Уведомления"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Badge count={Notif_list.length}>
          <img src={Notification} className="notification-svg" />
        </Badge>
      </Popover>
    </div>
  );
}
