import { useEffect, useState } from "react";
import { Pagination } from "antd";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
} from "../../LocalStorage/Locals";
import "../../App.css";
import "../../styles/NotificationStyle.css";
import { setSessionInLocalStorage } from "../../LocalStorage/Locals";
import NotificationCol from "./NotificationCol";
import { handleError403 } from "../../api";

type Notif_type = {
  id: number;
  noteDate: string;
  readDate: string;
  title: string;
  text: string;
};

export default function NotificationAll() {
  const params = new URLSearchParams(window.location.search);
  const count = params.get("count");
  const [currentPage, setCurrentPage] = useState(1);
  const login = getLoginStorage();
  const [Notif_list, setNotif_list] = useState<Notif_type[]>([]);
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=NOTE_LIST&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          "&archive=1" +
          "&page=" +
          currentPage +
          "&count=5"
      );
      responseStatus = response.status;
      const data = await response.json();
      setNotif_list(data.notes);
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    } finally {
    }
  };
  const handleChangePage = async (page: number) => {
    await fetchItemData();
    setCurrentPage(page);
    //console.log(page);
  };
  useEffect(() => {
    fetchItemData();
    //console.log("Текущая страница изменена:", currentPage);
  }, [currentPage]);

  return (
    <div>
      <div>
        {Notif_list.map((Item) => (
          <div key={Item.id}>
            <NotificationCol
              id={Item.id}
              title={Item.title}
              text={Item.text}
              noteDate={Item.noteDate}
              readDate={Item.readDate}
            />
          </div>
        ))}
        {count ? (
          <Pagination
            pageSize={5}
            current={currentPage}
            onChange={handleChangePage}
            total={parseInt(count)}
            disabled={parseInt(count) <= 5}
          />
        ) : (
          <p>Пока нет уведомлений</p>
        )}
      </div>
    </div>
  );
}
