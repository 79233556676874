import { useNavigate } from "react-router-dom";
import SpinnerComponent from "./SpinnerComponent";
import { useState } from "react";
import {
  setSessionInLocalStorage,
  setLoginStorage,
  setSecretKey,
  setSIGN,
  setPass,
  APP_VERSION,
} from "../LocalStorage/Locals";
import { Button, Form, Input, notification, Modal } from "antd";
import { Md5 } from "ts-md5";
import "../App.css";
import React, { useEffect } from "react";
import { getIPfaster } from "../api";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "../styles/LoginPageStyle.css";
import CaptchaBox from "./CaptchaBox";

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

type FieldType = {
  username?: string;
  password?: string;
};

const Login = () => {
  const [forceRegenerateCaptcha, setForceRegenerateCaptcha] = useState(0);
  const [correctCaptcha, setCorrectCaptcha] = useState(false);

  const onUserCaptchaInput = (valid: boolean) => {
    setCorrectCaptcha(valid);
  };

  const checkCaptcha = (): boolean => {
    console.log([correctCaptcha, ipGlobal]);
    if (correctCaptcha || (ipGlobal !== "" && ipGlobal !== null)) {
      return true; // Пользователь прошёл проверку
    } else {
      setForceRegenerateCaptcha(Math.random());
      notification.error({
        message: "Ошибка",
        description: "Введите символы корректно",
      });
      return false; // Пользователь не прошёл проверку
    }
  };
  const [ipFetching, setIpFetching] = useState(false);
  const [ipGlobal, setIPGlobal] = React.useState<string | null>(null);
  const onLoadPage = async () => {
    setIpFetching(true);
    const ip = await getIPfaster();
    if (ip === null) {
      setIpFetching(false);
      setIPGlobal("");
    } else {
      setIpFetching(false);
      setIPGlobal(ip);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [login, setLogin] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const isPhoneNumber = (login: string): boolean => {
    // Регулярное выражение для проверки номера телефона
    const phoneRegex = /^\+?7?\d{10,12}$/;
    return phoneRegex.test(login);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (isPhoneNumber(login)) {
      setIsModalOpen(true);
    } else {
      notification.error({
        message: "Ошибка",
        description: "Введите номер телефона корректно",
      });
    }
  };

  const RegModal = () => {
    fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=REG&phone_num=" +
        login +
        `&IP=${ipGlobal || ""}`
    );
    setIsModalOpen(false);
  };
  const ResendPass = async () => {
    if (isPhoneNumber(login)) {
      setIsLoadingResend(true);

      await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=REG&phone_num=" +
          login +
          "&RESEND=1" +
          `&IP=${ipGlobal || ""}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.ok === true) {
            notification.success({
              message: "Успех",
              description: "На ваш номер телефона выслан текущий пароль",
            });
          } else if (data.ok === false) {
            notification.error({
              message: "Ошибка",
              description: data.error,
            });
          }
        });
      setIsLoadingResend(false);
    }
  };
  const onSubmitMethod = async (e: any) => {
    e.preventDefault();
    const isCaptchaValid = checkCaptcha();
    if (!isCaptchaValid) {
      return; // Прекращаем выполнение функции, если капча неверна
    }
    try {
      if (login !== "") {
        setIsLoading(true);
        const fetchItemData = await fetch(
          "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LIST&login=" +
            login +
            "&password=" +
            password
        );
        const data = await fetchItemData.json();
        const fetchKey = await fetch(
          "https://plg.kras.ru/PlgApi.ashx?CMD=GETKEY&login=" +
            login +
            APP_VERSION +
            `&IP=${ipGlobal || ""}`
        );
        const key = await fetchKey.json();
        const checkLogin = async () => {
          if (data.ok === true) {
            setSessionInLocalStorage("true");
            setLoginStorage(login);
            setPass(password);
            setSecretKey(key);
            const md5HashKey = Md5.hashStr("G2vXrSAGm9").toString();
            const stringformd5 =
              login + "::" + password + "::" + key + "::1::::" + md5HashKey;
            const Sign = Md5.hashStr(stringformd5).toString();
            setSIGN(Sign);
            navigate("/main");
            setIsLoading(false);
          } else {
            setSessionInLocalStorage("false");
            console.log(data.ok);
          }
          if (data.ok === false) {
            console.log(data.error);
            notification.error({
              message: "Ошибка",
              description: data.error,
            });
            setIsLoading(false);
          }
        };
        checkLogin();
      }
    } catch (error) {
      console.log("Error");
      api.open({
        message: "Ошибка",
        description: "Что-то пошло не так",
        duration: 0,
      });
    }
  };
  useEffect(() => {
    onLoadPage();
  }, []);

  return (
    <div>
      {isLoading && <SpinnerComponent />}
      <div className="container4">
        {contextHolder}
        <h3 style={{ textAlign: "center" }}>
          Вход в личный кабинет <br /> Связь на вахте
        </h3>
        <Form
          onSubmitCapture={onSubmitMethod}
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="Номер телефона"
            name="username"
            rules={[{ required: true, message: "Введите логин" }]}
          >
            <Input
              type="number"
              className="custom-input"
              placeholder="71234567890"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Пароль"
            name="password"
            rules={[{ required: true, message: "Введите пароль" }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <CaptchaBox
            onUserCaptcha={onUserCaptchaInput}
            forceRegenerate={forceRegenerateCaptcha}
            status={
              ipGlobal === null
                ? "loading"
                : ipGlobal === ""
                ? "required"
                : "inessential"
            }
          />
          <div className="button-login-center">
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </div>
          <div className="button-login-center">
            <Spin
              spinning={isLoadingResend || ipFetching}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Button onClick={ResendPass}>Получить пароль по СМС</Button>
            </Spin>
          </div>
          <div className="button-login-center">
            <Spin
              spinning={ipFetching}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            >
              <Button onClick={showModal}>Получить новый пароль по СМС</Button>
            </Spin>

            <Modal
              title="Получение нового пароля"
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              footer={[
                <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                  Отмена
                </Button>,

                <Button key="ok" className="blue-button" onClick={RegModal}>
                  Отправить новый пароль
                </Button>,
              ]}
            >
              Ваш пароль будет изменен (!) и отправлен на Ваш номер{" "}
              <b> {login}</b> в СМС-сообщении. Если у вас нет мобильной сети то
              вы не сможете получить СМС-сообщение и зайти в личный кабинет!
            </Modal>
          </div>
          {ipGlobal !== null && ipGlobal !== "" ? (
            <div className="button-login-center" style={{ margin: "0px" }}>
              <a
                href="http://z.kras.ru/Views/PlgRegisterCard.aspx"
                className="button-like-2"
              >
                Регистрация по банковской карте
              </a>
              <br />
            </div>
          ) : (
            <div style={{ height: "49px" }}></div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Login;
