import React from "react";
import CardBuy from "../CardBuy";
import CardBuyInfo from "../CardBuyInfo";
import "../../styles/CardStyle.css";
import CardBuyAction from "./CardBuyAction";
type CardsData = {
  code: string;
  name?: string;
  descr: string;
  type: string;
  bgcolor: string;
  units: number;
  daysTotal?: number;
  traffic?: number;
  speed?: number;
  actionAvailable?: boolean;
};

const CardTariff: React.FC<CardsData> = ({
  code,
  descr,
  type,
  bgcolor,
  units,
  daysTotal,
  traffic,
  speed,
  name,
  actionAvailable,
}) => {
  return (
    <div>
      <div
        key={code}
        className="custom-card-2"
        style={{
          backgroundImage: bgcolor
            ? `linear-gradient(to right, #304782, ${bgcolor})`
            : `linear-gradient(to right, #304782, #304782)`,
        }}
      >
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            <p className="text">Номинал</p>
            <p className="text-nominal">{units}</p>
          </div>
          {actionAvailable ? (
            <div className="flex-row">
              <p className="text-action">Акция</p>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="flex-row ">
          <div className="flex-col">
            <p className="text-tariftype">{type}</p>
            <p className="text-tariftitle ">{name}</p>
          </div>
        </div>
        <div className="flex-row-buttons">
          <CardBuyInfo
            code={code}
            name={name}
            type={type}
            descr={descr}
            daysTotal={daysTotal}
            traffic={traffic}
            speed={speed}
          />
          {actionAvailable ? (
            <CardBuyAction code={code} name={name} units={units} />
          ) : (
            <CardBuy code={code} name={name} />
          )}
        </div>
      </div>
    </div>
  );
};
export default CardTariff;
