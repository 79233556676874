import { Button, Modal, notification } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLoginStorage,
  getSecretKey,
  getSIGN,
} from "../../LocalStorage/Locals";
import SpinnerComponent from "../SpinnerComponent";
type newPass = {
  password?: string;
};
const ResetPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [password, setPassword] = useState<newPass>();
  const fetchItemData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CHANGE_PASSWORD&login=" +
          `${getLoginStorage()}&KEY=${getSecretKey()}&SIGN=${getSIGN()}`
      );
      const data = await response.json();

      if (data.ok) {
        const { password } = data;
        setPassword({ password });

        setIsModalOpen2(true);
      } else if (data.error) {
        api.open({
          message: "Ошибка",
          description: data.error,
        });
      }
    } catch (error) {
      console.error("Произошла ошибка при загрузке данных:", error);
      api.open({
        message: "Ошибка",
        description: "Произошла ошибка при загрузке данных.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
    fetchItemData();
  };
  const Navigate = useNavigate();
  const logout = () => {
    Navigate("/login");
  };

  return (
    <>
      {contextHolder}
      <div className="main-container-acc">
        <Button className="blue-button" onClick={() => setIsModalOpen(true)}>
          Сменить пароль
        </Button>
        <Modal
          title="Смена пароля"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button key="cancel" onClick={() => setIsModalOpen(false)}>
              Отмена
            </Button>,
            <Button key="ok" className="blue-button" onClick={handleOk}>
              Изменить пароль
            </Button>,
          ]}
        >
          <p>
            После нажатия "Изменить пароль" ваш пароль будет изменен! Новый
            пароль отобразится на экране. Запишите новый пароль, чтобы не
            утратить доступ в личный кабинет.
          </p>
        </Modal>
        {isLoading && <SpinnerComponent />}
        <Modal
          closable={false}
          title="Ваш новый пароль"
          open={isModalOpen2}
          footer={[
            <Button key="ok" className="blue-button" onClick={() => logout()}>
              ОК
            </Button>,
          ]}
        >
          <p>
            <span style={{ fontWeight: "bold" }}>{password?.password}</span>{" "}
            <br />
            Запишите ваш новый пароль, чтобы не утратить доступ в личный
            кабинет. Или сделайте скриншот экрана. После нажатия ОК зайдите в
            личный кабинет с новым паролем.
          </p>
        </Modal>
      </div>
    </>
  );
};

export default ResetPassword;
