import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "../styles/CardStyle.css";
import HelpPic1 from "../Images/HelpInstr1.png";
import HelpPic2 from "../Images/HelpInstr2.png";
import HelpPic3 from "../Images/HelpInstr3.png";
import HelpPic4 from "../Images/HelpInstr4.png";
import HelpPic5 from "../Images/HelpInstr5.png";
import HelpPic6 from "../Images/HelpInstr6.png";
import HelpPic7 from "../Images/HelpInstr7.png";
import HelpPic8 from "../Images/HelpInstr8.png";
import HelpPic9 from "../Images/HelpInstr9.png";
import HelpPic10 from "../Images/HelpInstr10.png";
import HelpPic11 from "../Images/HelpInstr11.png";
import HelpPic12 from "../Images/HelpInstr12.png";

const CollapseHelpInstructions: React.FC = () => {
  const colitems1: CollapseProps["items"] = [
    {
      key: "1",
      label: "Пополнение с помощью СБП",
      children: (
        <div className="help-instr">
          <div className="help-instr-sub">
            <p>
              Выберите карту в своем личном кабинете и нажмите кнопку
              «Пополнить». Пополнить можно только лимитированную карту. Если на
              выбранной Вами карте отсутствует данная кнопка, значит функция
              пополнения не доступна. Также пополнение возможно в разделе «Архив
              карт», для этого необходимо нажать кнопку «Подробнее», далее
              «Пополнить».
            </p>
            <img src={HelpPic8} alt=""></img>
            <p>
              Введите сумму пополнения карты от 1000 рублей. В открывшемся
              диалоговом окне выберете способ оплаты - СПБ, затем нажмите кнопку
              “Пополнить”. Важно: при отсутствии доступа в интернет, через СПБ
              оплата возможна только через Сбербанк
            </p>
            <img src={HelpPic9} alt=""></img>
            <p>
              Если автоматический переход на страницу оплаты не произошел -
              нажмите на ссылку, в открывшемся диалоговом окне.
            </p>
            <img src={HelpPic6} alt=""></img>
            <p>
              При переходе по ссылке с использованием ПК система отобразит
              QR-код, который необходимо отсканировать и оплатить с помощью
              мобильного устройства.
            </p>
            <img src={HelpPic4} alt=""></img>
            <p>
              При переходе по ссылке с использованием мобильного устройства,
              необходимо выбрать банк оплаты, нажать на него и совершить оплату
              после перехода в банковское приложение.
            </p>
            <img src={HelpPic7} alt=""></img>
            <p>
              После подтверждения оплаты Банком внесенная сумма отобразится на
              балансе карты в Вашем личном кабинете.
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Пополнение с помощью банковской карты",
      children: (
        <div className="help-instr">
          <div className="help-instr-sub">
            <p>
              Выберите карту в своем личном кабинете и нажмите кнопку
              «Пополнить». Пополнить можно только лимитированную карту. Если на
              выбранной Вами карте отсутствует данная кнопка, значит функция
              пополнения не доступна. Также пополнение возможно в разделе «Архив
              карт», для этого необходимо нажать кнопку «Подробнее», далее
              «Пополнить».
            </p>
            <img src={HelpPic8} alt=""></img>
            <p>
              Введите сумму пополнения карты от 1000 рублей. В открывшемся
              диалоговом окне выберете способ оплаты - Карта, затем нажмите
              кнопку “Пополнить”.
            </p>
            <img src={HelpPic10} alt=""></img>
            <p>
              Если автоматический переход на страницу оплаты не произошел -
              нажмите на ссылку, в открывшемся диалоговом окне.
            </p>
            <img src={HelpPic6} alt=""></img>
            <p>
              На открывшейся странице оплаты введите реквизиты Вашей банковской
              карты и нажмите “Оплатить”.
            </p>
            <img src={HelpPic12} alt=""></img>
            <p>
              После подтверждения оплаты Банком внесенная сумма отобразится на
              балансе карты в Вашем личном кабинете.
            </p>
          </div>
        </div>
      ),
    },
  ];
  const colitems2: CollapseProps["items"] = [
    {
      key: "3",
      label: "Покупка с помощью СБП",
      children: (
        <div className="help-instr">
          <div className="help-instr-sub">
            <p>
              Зайдите в каталог, через кнопку “Выбрать новую карту” на главном
              экране или, нажав на раздел “Каталог”, в меню.{" "}
            </p>
            <img src={HelpPic11} style={{ maxWidth: "200px" }} alt=""></img>
            <p>Выберите карту и нажмите кнопку “Купить”.</p>
            <img src={HelpPic1} alt=""></img>
            <p>
              В открывшемся диалоговом окне выберете способ оплаты - СПБ. Затем
              нажмите кнопку “Купить”. Важно: при отсутствии доступа в интернет,
              через СПБ оплата возможна только через Сбербанк
            </p>
            <img src={HelpPic2} alt=""></img>
            <p>
              Если автоматический переход на страницу оплаты не произошел -
              нажмите на ссылку, в открывшемся диалоговом окне.
            </p>
            <img src={HelpPic6} alt=""></img>
            <p>
              При переходе по ссылке с использованием ПК система отобразит
              QR-код, который необходимо отсканировать и оплатить с помощью
              мобильного устройства.
            </p>
            <img src={HelpPic4} alt=""></img>
            <p>
              При переходе по ссылке с использованием мобильного устройства,
              необходимо выбрать банк оплаты, нажать на него и совершить оплату
              после перехода в банковское приложение.
            </p>
            <img src={HelpPic7} alt=""></img>
            <p>
              После подтверждения оплаты Банком карта отобразится в Вашем личном
              кабинете.
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: "Покупка с помощью банковской карты",
      children: (
        <div className="help-instr">
          <div className="help-instr-sub">
            <p>
              Зайдите в каталог, через кнопку “Выбрать новую карту” на главном
              экране или, нажав на раздел “Каталог”, в меню.{" "}
            </p>
            <img src={HelpPic11} style={{ maxWidth: "200px" }} alt=""></img>
            <p>Выберите карту и нажмите кнопку “Купить”.</p>
            <img src={HelpPic1} alt=""></img>
            <p>
              В открывшемся диалоговом окне выберете способ оплаты - Карта.
              Затем нажмите кнопку “Купить”.
            </p>
            <img src={HelpPic3} alt=""></img>
            <p>
              Если автоматический переход на страницу оплаты не произошел -
              нажмите на ссылку, в открывшемся диалоговом окне.
            </p>
            <img src={HelpPic6} alt=""></img>
            <p>
              На открывшейся странице оплаты введите реквизиты Вашей банковской
              карты и нажмите “Оплатить”.
            </p>
            <img src={HelpPic5} alt=""></img>
            <p>
              После подтверждения оплаты Банком карта отобразится в Вашем личном
              кабинете.
            </p>
          </div>
        </div>
      ),
    },
  ];
  const onChange = (key: string | string[]) => {};

  return (
    <div>
      <h1 className="Headline-Text"> Инструкции</h1>
      <h1 className="Headline-Text-2"> Инструкции по пополнению карты</h1>
      <Collapse size="large" items={colitems1} onChange={onChange} />
      <h1 className="Headline-Text-2"> Инструкции по покупке карты </h1>
      <Collapse size="large" items={colitems2} onChange={onChange} />
    </div>
  );
};
export default CollapseHelpInstructions;
