import "../styles/Spinner.css";
const SpinnerComponent = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default SpinnerComponent;
