import React, { useEffect, useState } from "react";
import { Button, Modal, Radio } from "antd";
import "../styles/ButtonStyle.css";
import "../styles/CardStyle.css";
import type { RadioChangeEvent } from "antd";
import { Switch } from "antd";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import { InfoCircleOutlined } from "@ant-design/icons";
import CardPay from "./CardPay";
type CardInfoProps = {
  cardNo: string;
  title: string;
  description?: string;
  display: string;
  cardLogin: string;
  cardPassword: string;
  isBlockable?: boolean;
  isUnblockable?: boolean;
  isReplenishable: boolean;
  nominal: number;
};
type SessionInfo = {
  date: number;
  volume: number;
};
type ReceiptInfo = {
  date: number;
  sum: number;
  url: string;
};

const CardInfoArchive: React.FC<CardInfoProps> = ({
  title,
  cardNo,
  description,
  display,
  cardLogin,
  cardPassword,
  isBlockable,
  isUnblockable,
  isReplenishable,
  nominal,
}) => {
  const blockState = isUnblockable;
  const [SessionHistory, setSessionHistory] = useState<SessionInfo[]>([]);
  const [isBlockStateChange, setBlockStateChange] = useState(isUnblockable);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayType, setdisplayType] = useState(display);
  const [hideInfo, sethideInfo] = useState(true);
  const [hideTable, sethideTable] = useState(false);
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const [Receipt, setReceipt] = useState<ReceiptInfo[]>([]);
  const SIGN = getSIGN();
  const [hideTable2, sethideTable2] = useState(false);
  const fetchItemData = async () => {
    await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_DISPLAY&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo +
        "&display=" +
        displayType
    );
  };
  const cardBlock = async () => {
    await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_BLOCK&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo +
        "&display=" +
        displayType
    );
  };
  const cardUnblock = async () => {
    await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_UNBLOCK&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo +
        "&display=" +
        displayType
    );
  };
  const cardHistory = async () => {
    await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=SESSION_LIST&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo +
        "&display=" +
        displayType +
        "&page=1&count=20"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSessionHistory(data.sessions);
        //console.log(data.sessions, SessionHistory);
      });
  };
  const cardReceipt = async () => {
    await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=RECEIPT_LIST&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setReceipt(data.receipts);
        //console.log(data.sessions, SessionHistory);
      });
  };
  const [checkedHide, setcheckHIde] = useState(false);
  const [checkedPin, setcheckPin] = useState(false);
  const showModal = async () => {
    //console.log(blockState, isBlockStateChange);
    switch (display) {
      case "D": {
        setcheckHIde(false);
        setcheckPin(false);
        break;
      }

      case "H": {
        setcheckHIde(true);
        setcheckPin(false);
        break;
      }
      case "S": {
        setcheckHIde(false);
        setcheckPin(true);
        break;
      }
    }
    await cardReceipt();
    await cardHistory();
    setIsModalOpen(true);
  };

  useEffect(() => {
    async function updateDisplayType() {
      if (checkedPin) {
        setdisplayType("S");
      } else if (checkedHide) {
        setdisplayType("H");
      } else {
        setdisplayType("D");
      }
    }

    updateDisplayType();
  }, [checkedPin, checkedHide]);
  const handleOk = async () => {
    await fetchItemData();
    setIsModalOpen(false);
    if (isBlockStateChange !== blockState) {
      switch (isBlockStateChange) {
        case true: {
          await cardBlock();
          break;
        }
        case false: {
          await cardUnblock();
          break;
        }
      }
    }

    window.location.reload(); //потом сделать не обновление
  };

  const onHideChange = (checked: boolean) => {
    setcheckHIde(!checkedHide);
    if (checked) setcheckPin(!checked);
  };
  const onPinChange = (checked: boolean) => {
    setcheckPin(checked);
    if (checked) setcheckHIde(!checked);
  };

  return (
    <>
      <Button className="blue-button" onClick={showModal}>
        <InfoCircleOutlined />
        Подробнее
      </Button>
      <Modal
        title="Информация о карте"
        open={isModalOpen}
        onOk={handleOk}
        cancelText="Отмена"
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Отмена
          </Button>,
          <Button key="ok" className="blue-button" onClick={handleOk}>
            ОК
          </Button>,
        ]}
      >
        <p>Тариф: {title}</p>
        <p>Номер карты: {cardNo}</p>
        {isReplenishable === true && (
          <CardPay key="cardPay" cardNo={cardNo} nominal={nominal} />
        )}
        <div>
          <p>Логин - {hideInfo ? "*****" : cardLogin}</p>
          <p>Пароль - {hideInfo ? "*****" : cardPassword}</p>
          <Button onClick={() => sethideInfo(!hideInfo)}>
            {hideInfo ? "Показать" : "Скрыть"}
          </Button>
        </div>
        <div className="flex-switch">
          <p>Скрыть</p>
          <div className="switch">
            <Switch checked={checkedHide} onChange={onHideChange} />
          </div>
        </div>
        <div className="flex-switch">
          <p>Закрепить</p>
          <div className="switch">
            <Switch checked={checkedPin} onChange={onPinChange} />
          </div>
        </div>
        <p>Описание: {description}</p>
        {isBlockable || isUnblockable ? (
          <Radio.Group
            onChange={(e: RadioChangeEvent) =>
              setBlockStateChange(e.target.value)
            }
            value={isBlockStateChange}
          >
            <Radio value={true}>Заблокирована</Radio>
            <Radio value={false}>Разблокирована</Radio>
          </Radio.Group>
        ) : null}
        <p />

        {SessionHistory.length !== 0 ? (
          <div>
            <Button
              onClick={() => sethideTable(!hideTable)}
              style={{ marginBottom: "8px" }}
            >
              {hideTable ? "Скрыть историю" : "Показать историю"}
            </Button>
            {hideTable ? (
              <div className="card-info">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}>История сессий за последние 30 дней</th>
                    </tr>
                  </thead>
                  <tbody>
                    {SessionHistory.map((session, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(session.date * 1000).toLocaleString()}
                        </td>
                        <td>{session.volume} Мбайт</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <p>Для этой карты не было ни одной сессии за последние 30 дней </p>
        )}
        {Receipt.length !== 0 ? (
          <div>
            <Button
              onClick={() => sethideTable2(!hideTable2)}
              style={{ marginBottom: "8px" }}
            >
              {hideTable2 ? "Скрыть чеки" : "Показать чеки"}
            </Button>
            {hideTable2 ? (
              <div className="card-info">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={1}>Дата</th>
                      <th colSpan={1}>Ссылка</th>
                      <th colSpan={1}>Сумма</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Receipt.map((receipt, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(receipt.date * 1000).toLocaleString()}
                        </td>
                        <td>
                          <a href={receipt.url} target="_blank">
                            Ссылка
                          </a>
                        </td>
                        <td>{receipt.sum} Руб</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <p>Для этой карты нет чеков </p>
        )}
      </Modal>
    </>
  );
};

export default CardInfoArchive;
