import CardsArchive from "../Components/CardListHistory";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../App.css";
import "../styles/PageStyle.css";
import card_archive from "../Images/card_archive.svg";
import ChatwootButton from "../Components/ChatwootButton";
function ArchivePage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <h1 className="Headline-Text">
            <img src={card_archive} className="archive-svg" />
            Архив карт
          </h1>
          <CardsArchive />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default ArchivePage;
