import { useEffect, useState } from "react";
import "../App.css";
import "../styles/Options.css";
import { Button, Modal, Radio, Drawer } from "antd";
import type { RadioChangeEvent } from "antd";
import placeholder from "../Images/Options_Placeholder.svg";
import { getIP } from "../api";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
  APP_VERSION,
} from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
type CardsData = {
  code: string;
  name: string;
  description: string;
  cardtypes: {
    code: string;
    units: number;
    title: string;
    description: string;
  }[];
  cardno: string;
};

const OptionsModal: React.FC<CardsData> = ({
  code,
  name,
  description,
  cardtypes,
  cardno,
}) => {
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const [isLoading, setIsLoading] = useState(false);
  const [PaymentType, setPaymentType] = useState("qr");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [url, setUrl] = useState("");
  const onClose = () => {
    setDrawerOpen(false);
  };
  const fetchItemData = async () => {
    setIsLoading(true);
    const ip = await getIP();
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_SALE&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&action_code=" +
        code +
        "&code=" +
        cardtypes[0].code +
        "&payment_type=" +
        PaymentType +
        APP_VERSION +
        `&IP=${ip || ""}`
    );
    const data = await response.json();
    setUrl(data.url);
    setIsLoading(false);
    window.open(data.url, "_blank");
    setDrawerOpen(true);
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <div onClick={() => setIsModalOpen(true)} className="opiton-box">
          {/* <p className="option-txt">{name}</p> */}
          <img src={placeholder} />
        </div>

        <p className="option-text-under">{name}</p>
        <Modal
          title={name}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button key="cancel" onClick={() => setIsModalOpen(false)}>
              Отмена
            </Button>,
            <Button key="ok" className="blue-button" onClick={fetchItemData}>
              Купить
            </Button>,
          ]}
        >
          <p>{cardtypes[0].description || description}</p>
          <p>Цена - {cardtypes[0].units}</p>
          <p
            style={{
              margin: "0",
              color: "rgba(0, 0, 0, 0.88);",
              fontWeight: "600",
              lineHeight: "1.5",
              fontSize: "16px",
              marginBottom: "16px",
            }}
          >
            Выберите способ оплаты
          </p>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => setPaymentType(e.target.value)}
            value={PaymentType}
          >
            <Radio value={"qr"}>СБП</Radio>
            <Radio value={"card"}>Карта</Radio>
          </Radio.Group>
          {isLoading && <SpinnerComponent />}
        </Modal>
        <Drawer
          title="Оплата"
          placement="top"
          onClose={onClose}
          open={drawerOpen}
          height={200}
        >
          <p>
            Если автоматический переход на страницу оплаты не произошел -
            нажмите на{" "}
            <a href={url} target="_blank">
              ссылку
            </a>
          </p>
        </Drawer>
      </div>
    </>
  );
};

export default OptionsModal;
