import React from "react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "../../styles/CardStyle.css";
import CardTariff from "./CardTariff";
type CardsData = {
  code: string;
  name?: string;
  descr: string;
  type: string;
  bgcolor: string;
  units: number;
  daysTotal?: number;
  traffic?: number;
  speed?: number;
  actionAvailable?: boolean;
};
type CardsDataProps = {
  carddata: CardsData[];
};

const CollapseTarrifs: React.FC<CardsDataProps> = ({ carddata }) => {
  const colitems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Лимитированные",
      children: (
        <div>
          <div className="card-container-2">
            {carddata.filter((item) =>
              item.type.toLowerCase().startsWith("лимит")
            ).length > 0 ? (
              carddata
                .filter((item) => item.type.toLowerCase().startsWith("лимит"))
                .map((item) => (
                  <div key={item.code}>
                    <CardTariff
                      code={item.code}
                      descr={item.descr}
                      type={item.type}
                      bgcolor={item.bgcolor}
                      units={item.units}
                      daysTotal={item.daysTotal}
                      traffic={item.traffic}
                      speed={item.speed}
                      name={item.name}
                      actionAvailable={item.actionAvailable}
                    />
                  </div>
                ))
            ) : (
              <p>Пока ничего нет</p>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Безлимитные",
      children: (
        <div>
          <div className="card-container-2">
            {carddata.filter((item) =>
              item.type.toLowerCase().startsWith("безлим")
            ).length > 0 ? (
              carddata
                .filter((item) => item.type.toLowerCase().startsWith("безлим"))
                .map((item) => (
                  <div key={item.code}>
                    <CardTariff
                      code={item.code}
                      descr={item.descr}
                      type={item.type}
                      bgcolor={item.bgcolor}
                      units={item.units}
                      daysTotal={item.daysTotal}
                      traffic={item.traffic}
                      speed={item.speed}
                      name={item.name}
                      actionAvailable={item.actionAvailable}
                    />
                  </div>
                ))
            ) : (
              <p>Пока ничего нет</p>
            )}
          </div>
        </div>
      ),
    },
    {
      key: "3",

      label: "Посуточные",
      children: (
        <div>
          <div className="card-container-2">
            {carddata.filter((item) =>
              item.type.toLowerCase().startsWith("посут")
            ).length > 0 ? (
              carddata
                .filter((item) => item.type.toLowerCase().startsWith("посут"))
                .map((item) => (
                  <div key={item.code}>
                    <CardTariff
                      code={item.code}
                      descr={item.descr}
                      type={item.type}
                      bgcolor={item.bgcolor}
                      units={item.units}
                      daysTotal={item.daysTotal}
                      traffic={item.traffic}
                      speed={item.speed}
                      name={item.name}
                      actionAvailable={item.actionAvailable}
                    />
                  </div>
                ))
            ) : (
              <p>Пока ничего нет</p>
            )}
          </div>
        </div>
      ),
    },
  ];

  const onChange = (key: string | string[]) => {};

  return (
    <div>
      <Collapse size="large" items={colitems} onChange={onChange} />
    </div>
  );
};
export default CollapseTarrifs;
