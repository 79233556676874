import "../styles/HelpChats.css";
import Help_telegram_logo from "../Images/Help_telegram_logo.svg";
import Help_call_logo from "../Images/Help_call_logo.svg";
import Help_mail_logo from "../Images/Help_mail_logo.svg";
import Help_vk_logo from "../Images/Help_vk_logo.svg";
const HelpChats: React.FC = () => {
  return (
    <>
      <div className="help-main-container">
        <div className="logo-container">
          <a
            href="tg://resolve/?domain=svyaznavahte_bot"
            target="_blank"
            className="a-container"
          >
            <img src={Help_telegram_logo} className="help-logo" />
          </a>
        </div>
        <div className="logo-container">
          <a href="tel:88043330284" target="_blank" className="a-container">
            <img src={Help_call_logo} className="help-logo" />
          </a>
        </div>
        <div className="logo-container">
          <a href="mailto:info@satsvyaz.ru" className="a-container">
            <img src={Help_mail_logo} className="help-logo" />
          </a>
        </div>
        <div className="logo-container">
          <a
            href="https://vk.com/svyaznavahte"
            target="_blank"
            className="a-container"
          >
            <img src={Help_vk_logo} className="help-logo" />
          </a>
        </div>
      </div>
    </>
  );
};
export default HelpChats;
