import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ChatwootButton from "../Components/ChatwootButton";
import EndAllSessions from "../Components/Account/EndAllSession";
import AccuntDetails from "../Components/Account/AccountDetails";
import SettingsLogo from "../Images/Settings.svg";
import ResetPassword from "../Components/Account/ResetPassword";
function AccountPage() {
  return (
    <div>
      <Header />
      <div className="wrapper">
        <div className="content">
          <h1 className="Headline-Text">
            <img src={SettingsLogo} className="user-acc-svg" />
            Настройки
          </h1>
          <AccuntDetails />
          <EndAllSessions />
          <ResetPassword />
        </div>
      </div>
      <ChatwootButton />
      <Footer />
    </div>
  );
}

export default AccountPage;
