import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import { useEffect, useState } from "react";
import "../App.css";
import "../styles/CardStyle.css";
import "../styles/TextStyles.css";
import SpinnerComponent from "./SpinnerComponent";
import CollapseTarrifs from "./Tarrifs/CollapseTariffs";
import { setSessionInLocalStorage } from "../LocalStorage/Locals";
import { getIP, handleError403 } from "../api";
type CardsData = {
  code: string;
  name?: string;
  descr: string;
  type: string;
  bgcolor: string;
  units: number;
  daysTotal?: number;
  traffic?: number;
  speed?: number;
  actionAvailable?: boolean;
};
const Tariffs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [Items, setItems] = useState<CardsData[]>([]);
  const login = getLoginStorage();

  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      setIsLoading(true);
      const ip = await getIP();
      const request = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_TYPES&" +
          `LOGIN=${login}&KEY=${getSecretKey()}&SIGN=${getSIGN()}&IP=${
            ip || ""
          }`
      );
      responseStatus = request.status;
      const json = await request.json();
      const data = json.cardtypes;
      setItems(data);
    } catch (error) {
      console.log("Error:", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchItemData();
  }, []);
  return (
    <div>
      {isLoading && <SpinnerComponent />}

      <CollapseTarrifs carddata={Items} />
    </div>
  );
};
export default Tariffs;
