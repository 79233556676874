import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
  setSessionInLocalStorage,
} from "../../LocalStorage/Locals";
import "../../App.css";
import "../../styles/NotificationStyle.css";
import { CheckCircleOutlined } from "@ant-design/icons";
import { handleError403 } from "../../api";
type idProps = {
  id: number;
};
interface ChildComponentProps {
  update: boolean;
  onUpdateChange: (newUpdate: boolean) => void;
}
const NotificationRead: React.FC<idProps & ChildComponentProps> = ({
  id,
  onUpdateChange,
  update,
}) => {
  const login = getLoginStorage();
  const fetchItemData = async () => {
    let responseStatus = null;
    try {
      const response = await fetch(
        "https://plg.kras.ru/PlgApi.ashx?CMD=NOTE_READ&login=" +
          login +
          "&KEY=" +
          getSecretKey() +
          "&SIGN=" +
          getSIGN() +
          "&note_id=" +
          id
      );
      responseStatus = response.status;
    } catch (error) {
      console.log("Error", error);
      if (responseStatus === 403) {
        if (login) await handleError403(login);
        await fetchItemData();
      } else {
        setSessionInLocalStorage("false");
      }
    }
  };

  return (
    <div
      onClick={() => {
        fetchItemData();
        onUpdateChange(!update);
      }}
      className="read-style"
    >
      <CheckCircleOutlined className="icon" />
      Отметить как прочитано
    </div>
  );
};
export default NotificationRead;
