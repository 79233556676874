import React, { useState } from "react";
import { Button, notification } from "antd";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import "../styles/Spinner.css";
import "../App.css";
import "../styles/ButtonStyle.css";
import Power_off from "../Images/Power_off.svg";
type CardInfoProps = {
  cardNo?: string;
};
interface ChildComponentProps {
  update: boolean;
  onUpdateChange: (newUpdate: boolean) => void;
}
const CardDeactivate: React.FC<CardInfoProps & ChildComponentProps> = ({
  cardNo,
  update,
  onUpdateChange,
}) => {
  const handleButtonClick = () => {
    const newUpdate = !update;
    onUpdateChange(newUpdate);
  };
  const [isLoading, setIsLoading] = useState(false);
  const login = getLoginStorage();
  const [api, contextHolder] = notification.useNotification();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchItemData = async () => {
    setIsLoading(true);
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_LOGOFF&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&cardNo=" +
        cardNo
    );
    const data = await response.json();
    if (data.ok === true) {
      handleButtonClick();
      setIsLoading(false);
      api.open({
        message: "Успех",
        description: data.message,
      });
      console.log("yes");
    }
    if (data.ok === false) {
      console.log(data.error);
      api.open({
        message: "Ошибка",
        description: data.error,
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <Button className="deactivate-button" onClick={fetchItemData}>
        <img src={Power_off} /> <p>Стоп</p>
      </Button>
      {isLoading && <SpinnerComponent />}
    </div>
  );
};

export default CardDeactivate;
